// Fonts
$base-font-family: "Open Sans" !default;
$reading-font-family: "Roboto Slab", "Noto Serif SC", "Noto Serif TC" !default;
$headline-font-family: Roboto, "Noto Sans SC", "Noto Sans TC" !default;
$code-font-family:  Inconsolata !default;
$handwriting-font-family: "Dancing Script", "Ma Shan Zheng" !default;
$base-font-size:    18px !default;
$base-line-height:  1.5 !default;

// Layouts
$spacing-unit:      2rem !default;
$content-width:     1100px !default;

$on-small:          600px !default;
$on-medium:         900px !default;
$on-large:          1100px !default;
